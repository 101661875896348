import React, { Fragment } from "react";

const Login = () => {
  return (
    <Fragment>
      <div>Login</div>
    </Fragment>
  );
};

export default Login;
