import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navbar">
      <header className="header header-14">
        <div className="header-top">
          <div className="container">
            <div className="header-left">
              <a href="tel:#">
                <i className="icon-phone"></i>Call: +0123 456 789
              </a>
            </div>
            <div className="header-right">
              <a href="#signin-modal" data-toggle="modal">
                Sign in / Sign up
              </a>
            </div>
          </div>
        </div>

        <div className="header-middle">
          <div className="container-fluid">
            <div className="row">
              <div className="col-auto col-lg-3 col-xl-3 col-xxl-2">
                <button className="mobile-menu-toggler">
                  <span className="sr-only">Toggle mobile menu</span>
                  <i className="icon-bars"></i>
                </button>
                <a href="/" className="logo">
                  <img
                    src="/assets/images/demos/demo-14/logo.png"
                    alt="Molla Logo"
                    width="105"
                    height="25"
                  />
                </a>
              </div>

              <div className="col col-lg-9 col-xl-9 col-xxl-10 header-middle-right">
                <div className="row">
                  <div className="col-lg-8 col-xxl-4-5col d-none d-lg-block">
                    <div className="header-search header-search-extended header-search-visible header-search-no-radius">
                      <a href="/" className="search-toggle" role="button">
                        <i className="icon-search"></i>
                      </a>
                      <form action="#" method="get">
                        <div className="header-search-wrapper search-wrapper-wide">
                          <label htmlFor="q" className="sr-only">
                            Search
                          </label>
                          <input
                            type="search"
                            className="form-control"
                            name="q"
                            id="q"
                            placeholder="Search product ..."
                            required
                          />

                          <button className="btn btn-primary" type="submit">
                            <i className="icon-search"></i>
                          </button>
                        </div>
                        {/*End .header-search-wrapper */}
                      </form>
                    </div>
                  </div>

                  <div className="col-lg-4 col-xxl-5col d-flex justify-content-end align-items-center">
                    <div className="header-dropdown-link">
                      <Link className="link wishlist-link" to="/wishlist">
                        <i className="icon-heart-o"></i>
                        <span className="wishlist-count">3</span>
                        <span className="wishlist-txt">Wishlist</span>
                      </Link>

                      <div className="dropdown cart-dropdown">
                        <Link
                          to="/cart"
                          className="link dropdown-toggle"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-display="static"
                        >
                          <i className="icon-shopping-cart"></i>
                          <span className="cart-count">2</span>
                          <span className="cart-txt">Cart</span>
                        </Link>

                        <div className="dropdown-menu dropdown-menu-right">
                          <div className="dropdown-cart-products">
                            <div className="product">
                              <div className="product-cart-details">
                                <h4 className="product-title">
                                  <a href="product.html">
                                    Beige knitted elastic runner shoes
                                  </a>
                                </h4>

                                <span className="cart-product-info">
                                  <span className="cart-product-qty">1</span>x
                                  $84.00
                                </span>
                              </div>

                              <figure className="product-image-container">
                                <a
                                  href="product.html"
                                  className="product-image"
                                >
                                  <img
                                    src="/assets/images/products/cart/product-1.jpg"
                                    alt="product"
                                  />
                                </a>
                              </figure>
                              <a
                                href="/"
                                className="btn-remove"
                                title="Remove Product"
                              >
                                <i className="icon-close"></i>
                              </a>
                            </div>

                            <div className="product">
                              <div className="product-cart-details">
                                <h4 className="product-title">
                                  <a href="product.html">
                                    Blue utility pinafore denim dress
                                  </a>
                                </h4>

                                <span className="cart-product-info">
                                  <span className="cart-product-qty">1</span>x
                                  $76.00
                                </span>
                              </div>

                              <figure className="product-image-container">
                                <a
                                  href="product.html"
                                  className="product-image"
                                >
                                  <img
                                    src="/assets/images/products/cart/product-2.jpg"
                                    alt="product"
                                  />
                                </a>
                              </figure>
                              <a
                                href="/"
                                className="btn-remove"
                                title="Remove Product"
                              >
                                <i className="icon-close"></i>
                              </a>
                            </div>
                          </div>

                          <div className="dropdown-cart-total">
                            <span>Total</span>

                            <span className="cart-total-price">$160.00</span>
                          </div>

                          <div className="dropdown-cart-action">
                            <Link to="/cart" className="link btn btn-primary">
                              View Cart
                            </Link>
                            <Link
                              to="/checkout"
                              className="link btn btn-outline-primary-2"
                            >
                              <span>Checkout</span>
                              <i className="icon-long-arrow-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-bottom sticky-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-auto col-lg-3 col-xl-3 col-xxl-2 header-left">
                <div
                  className="dropdown category-dropdown show is-on"
                  data-visible="true"
                >
                  <a
                    href="/"
                    className="dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-display="static"
                    title="Browse Categories"
                  >
                    Browse Categories
                  </a>

                  <div className="dropdown-menu">
                    <nav className="side-nav">
                      <ul className="menu-vertical sf-arrows">
                        <li className="megamenu-container">
                          <a className="sf-with-ul" href="/">
                            <i className="icon-laptop"></i>Electronics
                          </a>

                          <div className="megamenu">
                            <div className="row no-gutters">
                              <div className="col-md-8">
                                <div className="menu-col">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="menu-title">
                                        Laptops & Computers
                                      </div>
                                      <ul>
                                        <li>
                                          <a href="/">Desktop Computers</a>
                                        </li>
                                        <li>
                                          <a href="/">Monitors</a>
                                        </li>
                                        <li>
                                          <a href="/">Laptops</a>
                                        </li>
                                        <li>
                                          <a href="/">iPad & Tablets</a>
                                        </li>
                                        <li>
                                          <a href="/">Hard Drives & Storage</a>
                                        </li>
                                        <li>
                                          <a href="/">Printers & Supplies</a>
                                        </li>
                                        <li>
                                          <a href="/">Computer Accessories</a>
                                        </li>
                                      </ul>

                                      <div className="menu-title">
                                        TV & Video
                                      </div>
                                      <ul>
                                        <li>
                                          <a href="/">TVs</a>
                                        </li>
                                        <li>
                                          <a href="/">Home Audio Speakers</a>
                                        </li>
                                        <li>
                                          <a href="/">Projectors</a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            Media Streaming Devices
                                          </a>
                                        </li>
                                      </ul>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="menu-title">
                                        Cell Phones
                                      </div>
                                      <ul>
                                        <li>
                                          <a href="/">Carrier Phones</a>
                                        </li>
                                        <li>
                                          <a href="/">Unlocked Phones</a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            Phone & Cellphone Cases
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">Cellphone Chargers </a>
                                        </li>
                                      </ul>

                                      <div className="menu-title">
                                        Digital Cameras
                                      </div>
                                      <ul>
                                        <li>
                                          <a href="/">Digital SLR Cameras</a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            Sports & Action Cameras
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">Camcorders</a>
                                        </li>
                                        <li>
                                          <a href="/">Camera Lenses</a>
                                        </li>
                                        <li>
                                          <a href="/">Photo Printer</a>
                                        </li>
                                        <li>
                                          <a href="/">Digital Memory Cards</a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            Camera Bags, Backpacks & Cases
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <div className="banner banner-overlay">
                                  <a
                                    href="category.html"
                                    className="banner banner-menu"
                                  >
                                    <img
                                      src="/assets/images/demos/demo-13/menu/banner-1.jpg"
                                      alt="Banner"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="megamenu-container">
                          <a className="sf-with-ul" href="/">
                            <i className="icon-couch"></i>Furniture
                          </a>

                          <div className="megamenu">
                            <div className="row no-gutters">
                              <div className="col-md-8">
                                <div className="menu-col">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="menu-title">Bedroom</div>
                                      <ul>
                                        <li>
                                          <a href="/">Beds, Frames & Bases</a>
                                        </li>
                                        <li>
                                          <a href="/">Dressers</a>
                                        </li>
                                        <li>
                                          <a href="/">Nightstands</a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            Kids& Beds & Headboards
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">Armoires</a>
                                        </li>
                                      </ul>

                                      <div className="menu-title">
                                        Living Room
                                      </div>
                                      <ul>
                                        <li>
                                          <a href="/">Coffee Tables</a>
                                        </li>
                                        <li>
                                          <a href="/">Chairs</a>
                                        </li>
                                        <li>
                                          <a href="/">Tables</a>
                                        </li>
                                        <li>
                                          <a href="/">Futons & Sofa Beds</a>
                                        </li>
                                        <li>
                                          <a href="/">Cabinets & Chests</a>
                                        </li>
                                      </ul>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="menu-title">Office</div>
                                      <ul>
                                        <li>
                                          <a href="/">Office Chairs</a>
                                        </li>
                                        <li>
                                          <a href="/">Desks</a>
                                        </li>
                                        <li>
                                          <a href="/">Bookcases</a>
                                        </li>
                                        <li>
                                          <a href="/">File Cabinets</a>
                                        </li>
                                        <li>
                                          <a href="/">Breakroom Tables</a>
                                        </li>
                                      </ul>

                                      <div className="menu-title">
                                        Kitchen & Dining
                                      </div>
                                      <ul>
                                        <li>
                                          <a href="/">Dining Sets</a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            Kitchen Storage Cabinets
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">Bakers Racks</a>
                                        </li>
                                        <li>
                                          <a href="/">Dining Chairs</a>
                                        </li>
                                        <li>
                                          <a href="/">Dining Room Tables</a>
                                        </li>
                                        <li>
                                          <a href="/">Bar Stools</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="banner banner-overlay">
                                  <a
                                    href="category.html"
                                    className="banner banner-menu"
                                  >
                                    <img
                                      src="/assets/images/demos/demo-13/menu/banner-2.jpg"
                                      alt="Banner"
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="megamenu-container">
                          <a className="sf-with-ul" href="/">
                            <i className="icon-concierge-bell"></i>Cooking
                          </a>

                          <div className="megamenu">
                            <div className="menu-col">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="menu-title">Cookware</div>
                                  {/*End .menu-title */}
                                  <ul>
                                    <li>
                                      <a href="/">Cookware Sets</a>
                                    </li>
                                    <li>
                                      <a href="/">Pans, Griddles & Woks</a>
                                    </li>
                                    <li>
                                      <a href="/">Pots</a>
                                    </li>
                                    <li>
                                      <a href="/">Skillets & Grill Pans</a>
                                    </li>
                                    <li>
                                      <a href="/">Kettles</a>
                                    </li>
                                    <li>
                                      <a href="/">Soup & Stockpots</a>
                                    </li>
                                  </ul>
                                </div>
                                {/*End .col-md-4 */}

                                <div className="col-md-4">
                                  <div className="menu-title">
                                    Dinnerware & Tabletop
                                  </div>
                                  {/*End .menu-title */}
                                  <ul>
                                    <li>
                                      <a href="/">Plates</a>
                                    </li>
                                    <li>
                                      <a href="/">Cups & Mugs</a>
                                    </li>
                                    <li>
                                      <a href="/">Trays & Platters</a>
                                    </li>
                                    <li>
                                      <a href="/">Coffee & Tea Serving</a>
                                    </li>
                                    <li>
                                      <a href="/">Salt & Pepper Shaker</a>
                                    </li>
                                  </ul>
                                </div>
                                {/*End .col-md-4 */}

                                <div className="col-md-4">
                                  <div className="menu-title">
                                    Cooking Appliances
                                  </div>
                                  {/*End .menu-title */}
                                  <ul>
                                    <li>
                                      <a href="/">Microwaves</a>
                                    </li>
                                    <li>
                                      <a href="/">Coffee Makers</a>
                                    </li>
                                    <li>
                                      <a href="/">Mixers & Attachments</a>
                                    </li>
                                    <li>
                                      <a href="/">Slow Cookers</a>
                                    </li>
                                    <li>
                                      <a href="/">Air Fryers</a>
                                    </li>
                                    <li>
                                      <a href="/">Toasters & Ovens</a>
                                    </li>
                                  </ul>
                                </div>
                                {/*End .col-md-4 */}
                              </div>
                              {/*End .row */}

                              <div className="row menu-banners">
                                <div className="col-md-4">
                                  <div className="banner">
                                    <a href="/">
                                      <img
                                        src="/assets/images/demos/demo-13/menu/1.jpg"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  {/*End .banner */}
                                </div>
                                {/*End .col-md-4 */}

                                <div className="col-md-4">
                                  <div className="banner">
                                    <a href="/">
                                      <img
                                        src="/assets/images/demos/demo-13/menu/2.jpg"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  {/*End .banner */}
                                </div>
                                {/*End .col-md-4 */}

                                <div className="col-md-4">
                                  <div className="banner">
                                    <a href="/">
                                      <img
                                        src="/assets/images/demos/demo-13/menu/3.jpg"
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                  {/*End .banner */}
                                </div>
                                {/*End .col-md-4 */}
                              </div>
                              {/*End .row */}
                            </div>
                            {/*End .menu-col */}
                          </div>
                          {/*End .megamenu */}
                        </li>
                        <li className="megamenu-container">
                          <a className="sf-with-ul" href="/">
                            <i className="icon-tshirt"></i>Clothing
                          </a>

                          <div className="megamenu">
                            <div className="row no-gutters">
                              <div className="col-md-8">
                                <div className="menu-col">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="menu-title">Women</div>
                                      {/*End .menu-title */}
                                      <ul>
                                        <li>
                                          <a href="/">
                                            <strong>New Arrivals</strong>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <strong>Best Sellers</strong>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <strong>Trending</strong>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">Clothing</a>
                                        </li>
                                        <li>
                                          <a href="/">Shoes</a>
                                        </li>
                                        <li>
                                          <a href="/">Bags</a>
                                        </li>
                                        <li>
                                          <a href="/">Accessories</a>
                                        </li>
                                        <li>
                                          <a href="/">Jewlery & Watches</a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <strong>Sale</strong>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                    {/*End .col-md-6 */}

                                    <div className="col-md-6">
                                      <div className="menu-title">Men</div>
                                      {/*End .menu-title */}
                                      <ul>
                                        <li>
                                          <a href="/">
                                            <strong>New Arrivals</strong>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <strong>Best Sellers</strong>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">
                                            <strong>Trending</strong>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="/">Clothing</a>
                                        </li>
                                        <li>
                                          <a href="/">Shoes</a>
                                        </li>
                                        <li>
                                          <a href="/">Bags</a>
                                        </li>
                                        <li>
                                          <a href="/">Accessories</a>
                                        </li>
                                        <li>
                                          <a href="/">Jewlery & Watches</a>
                                        </li>
                                      </ul>
                                    </div>
                                    {/*End .col-md-6 */}
                                  </div>
                                  {/*End .row */}
                                </div>
                                {/*End .menu-col */}
                              </div>
                              {/*End .col-md-8 */}

                              <div className="col-md-4">
                                <div className="banner banner-overlay">
                                  <a
                                    href="category.html"
                                    className="banner banner-menu"
                                  >
                                    <img
                                      src="/assets/images/demos/demo-13/menu/banner-3.jpg"
                                      alt="Banner"
                                    />
                                  </a>
                                </div>
                                {/*End .banner banner-overlay */}
                              </div>
                              {/*End .col-md-4 */}
                            </div>
                            {/*End .row */}

                            <div className="menu-col menu-brands">
                              <div className="row">
                                <div className="col-lg-2">
                                  <a href="/" className="brand">
                                    <img
                                      src="/assets/images/brands/1.png"
                                      alt="Brand Name"
                                    />
                                  </a>
                                </div>
                                {/*End .col-lg-2 */}

                                <div className="col-lg-2">
                                  <a href="/" className="brand">
                                    <img
                                      src="/assets/images/brands/2.png"
                                      alt="Brand Name"
                                    />
                                  </a>
                                </div>
                                {/*End .col-lg-2 */}

                                <div className="col-lg-2">
                                  <a href="/" className="brand">
                                    <img
                                      src="/assets/images/brands/3.png"
                                      alt="Brand Name"
                                    />
                                  </a>
                                </div>
                                {/*End .col-lg-2 */}

                                <div className="col-lg-2">
                                  <a href="/" className="brand">
                                    <img
                                      src="/assets/images/brands/4.png"
                                      alt="Brand Name"
                                    />
                                  </a>
                                </div>
                                {/*End .col-lg-2 */}

                                <div className="col-lg-2">
                                  <a href="/" className="brand">
                                    <img
                                      src="/assets/images/brands/5.png"
                                      alt="Brand Name"
                                    />
                                  </a>
                                </div>
                                {/*End .col-lg-2 */}

                                <div className="col-lg-2">
                                  <a href="/" className="brand">
                                    <img
                                      src="/assets/images/brands/6.png"
                                      alt="Brand Name"
                                    />
                                  </a>
                                </div>
                                {/*End .col-lg-2 */}
                              </div>
                              {/*End .row */}
                            </div>
                            {/*End .menu-brands */}
                          </div>
                          {/*End .megamenu */}
                        </li>
                        <li>
                          <a href="/">
                            <i className="icon-blender"></i>Home Appliances
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <i className="icon-heartbeat"></i>Healthy & Beauty
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <i className="icon-shoe-prints"></i>Shoes & Boots
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <i className="icon-map-signs"></i>Travel & Outdoor
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <i className="icon-mobile-alt"></i>Smart Phones
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <i className="icon-tv"></i>TV & Audio
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <i className="icon-shopping-bag"></i>Backpack & Bag
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <i className="icon-music"></i>Musical Instruments
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <i className="icon-gift"></i>Gift Ideas
                          </a>
                        </li>
                      </ul>
                      {/*End .menu-vertical */}
                    </nav>
                    {/*End .side-nav */}
                  </div>
                  {/*End .dropdown-menu */}
                </div>
                {/*End .category-dropdown */}
              </div>
              {/*End .col-xl-3 col-xxl-2 */}

              <div className="col col-lg-6 col-xl-6 col-xxl-8 header-center">
                <nav className="main-nav">
                  <ul className="menu megamenu-container active">
                    <li>
                      <Link className="link" to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to="/products">
                        Products
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to="/about">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to="/contact">
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link className="link" to="/faqs">
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile menu side Nav */}
      <div className="mobile-menu-overlay"></div>

      <div className="mobile-menu-container">
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close">
            <i className="icon-close"></i>
          </span>

          <form action="#" method="get" className="mobile-search">
            <label htmlFor="mobile-search" className="sr-only">
              Search
            </label>
            <input
              type="search"
              className="form-control"
              name="mobile-search"
              id="mobile-search"
              placeholder="Search in..."
              required
            />
            <button className="btn btn-primary" type="submit">
              <i className="icon-search"></i>
            </button>
          </form>

          <ul className="nav nav-pills-mobile" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="mobile-menu-link"
                data-toggle="tab"
                href="#mobile-menu-tab"
                role="tab"
                aria-controls="mobile-menu-tab"
                aria-selected="true"
              >
                Menu
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="mobile-cats-link"
                data-toggle="tab"
                href="#mobile-cats-tab"
                role="tab"
                aria-controls="mobile-cats-tab"
                aria-selected="false"
              >
                Categories
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="mobile-menu-tab"
              role="tabpanel"
              aria-labelledby="mobile-menu-link"
            >
              <nav className="mobile-nav">
                <ul className="mobile-menu">
                  <li className="active">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/">About</a>
                  </li>
                  <li>
                    <a href="/">Contact</a>
                  </li>
                  <li>
                    <a href="/">Blog</a>
                  </li>
                </ul>
              </nav>
              {/*End .mobile-nav */}
            </div>
            {/*.End .tab-pane */}
            <div
              className="tab-pane fade"
              id="mobile-cats-tab"
              role="tabpanel"
              aria-labelledby="mobile-cats-link"
            >
              <nav className="mobile-cats-nav">
                <ul className="mobile-cats-menu">
                  <li>
                    <a className="mobile-cats-lead" href="/">
                      Daily offers
                    </a>
                  </li>
                  <li>
                    <a className="mobile-cats-lead" href="/">
                      Gift Ideas
                    </a>
                  </li>
                  <li>
                    <a href="/">Beds</a>
                  </li>
                  <li>
                    <a href="/">Lighting</a>
                  </li>
                  <li>
                    <a href="/">Sofas & Sleeper sofas</a>
                  </li>
                  <li>
                    <a href="/">Storage</a>
                  </li>
                  <li>
                    <a href="/">Armchairs & Chaises</a>
                  </li>
                  <li>
                    <a href="/">Decoration </a>
                  </li>
                  <li>
                    <a href="/">Kitchen Cabinets</a>
                  </li>
                  <li>
                    <a href="/">Coffee & Tables</a>
                  </li>
                  <li>
                    <a href="/">Outdoor Furniture </a>
                  </li>
                </ul>
                {/*End .mobile-cats-menu */}
              </nav>
              {/*End .mobile-cats-nav */}
            </div>
            {/*.End .tab-pane */}
          </div>
          {/*End .tab-content */}

          <div className="social-icons">
            <a
              href="/"
              className="social-icon"
              target="_blank"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </a>
            <a href="/" className="social-icon" target="_blank" title="Twitter">
              <i className="icon-twitter"></i>
            </a>
            <a
              href="/"
              className="social-icon"
              target="_blank"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </a>
            <a href="/" className="social-icon" target="_blank" title="Youtube">
              <i className="icon-youtube"></i>
            </a>
          </div>
          {/*End .social-icons */}
        </div>
        {/*End .mobile-menu-wrapper */}
      </div>
      {/* Mobile menu side Nav end */}
    </div>
  );
};

export default Navbar;

// const SecondNav = () => {
//   return (
//     <header className="header">
//       <div className="header-top">
//         <div className="container">
//           <div className="header-left">
//             <a href="tel:#">
//               <i className="icon-phone" />
//               Call: +0123 456 789
//             </a>
//           </div>
//           {/* End .header-left */}
//           <div className="header-right">
//             <ul className="top-menu">
//               <li>
//                 <a href="#">Links</a>
//                 <ul>
//                   <li>
//                     <a href="wishlist.html">
//                       <i className="icon-heart-o" />
//                       Wishlist <span>(3)</span>
//                     </a>
//                   </li>
//                   <li>
//                     <a href="about.html">About Us</a>
//                   </li>
//                   <li>
//                     <a href="contact.html">Contact Us</a>
//                   </li>
//                   <li>
//                     <a href="#signin-modal" data-toggle="modal">
//                       <i className="icon-user" />
//                       Login
//                     </a>
//                   </li>
//                 </ul>
//               </li>
//             </ul>
//             {/* End .top-menu */}
//           </div>
//           {/* End .header-right */}
//         </div>
//         {/* End .container */}
//       </div>
//       {/* End .header-top */}
//       <div className="header-middle sticky-header">
//         <div className="container">
//           <div className="header-left">
//             <button className="mobile-menu-toggler">
//               <span className="sr-only">Toggle mobile menu</span>
//               <i className="icon-bars" />
//             </button>
//             <a href="index.html" className="logo">
//               <img
//                 src="assets/images/logo.png"
//                 alt="Molla Logo"
//                 width={105}
//                 height={25}
//               />
//             </a>
//             <nav className="main-nav">
//               <ul className="menu">
//                 <li className="megamenu-container active">
//                   <a href="index.html" className="sf-with-ul">
//                     Home
//                   </a>
//                 </li>
//                 <li>
//                   <a href="category.html" className="sf-with-ul">
//                     Categories
//                   </a>
//                 </li>
//                 <li>
//                   <a href="About.jsx" className="sf-with-ul">
//                     About
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" className="sf-with-ul">
//                     Contact
//                   </a>
//                 </li>
//                 <li>
//                   <a href="blog.html" className="sf-with-ul">
//                     Blog
//                   </a>
//                 </li>
//                 <li>
//                   <a href="elements-list.html" className="sf-with-ul">
//                     Elements
//                   </a>
//                 </li>
//               </ul>
//               {/* End .menu */}
//             </nav>
//             {/* End .main-nav */}
//           </div>
//           {/* End .header-left */}
//           <div className="header-right">
//             <div className="header-search">
//               <a
//                 href="#"
//                 className="search-toggle"
//                 role="button"
//                 title="Search"
//               >
//                 <i className="icon-search" />
//               </a>
//               <form action="#" method="get">
//                 <div className="header-search-wrapper">
//                   <label htmlFor="q" className="sr-only">
//                     Search
//                   </label>
//                   <input
//                     type="search"
//                     className="form-control"
//                     name="q"
//                     id="q"
//                     placeholder="Search in..."
//                     required
//                   />
//                 </div>
//                 {/* End .header-search-wrapper */}
//               </form>
//             </div>
//             {/* End .header-search */}

//             <div className="dropdown cart-dropdown">
//               <a
//                 href="#"
//                 className="dropdown-toggle"
//                 role="button"
//                 data-toggle="dropdown"
//                 aria-haspopup="true"
//                 aria-expanded="false"
//                 data-display="static"
//               >
//                 <i className="icon-shopping-cart" />
//                 <span className="cart-count">2</span>
//               </a>
//               <div className="dropdown-menu dropdown-menu-right">
//                 <div className="dropdown-cart-products">
//                   <div className="product">
//                     <div className="product-cart-details">
//                       <h4 className="product-title">
//                         <a href="product.html">
//                           Beige knitted elastic runner shoes
//                         </a>
//                       </h4>
//                       <span className="cart-product-info">
//                         <span className="cart-product-qty">1</span>x $84.00
//                       </span>
//                     </div>
//                     {/* End .product-cart-details */}
//                     <figure className="product-image-container">
//                       <a href="product.html" className="product-image">
//                         <img
//                           src="assets/images/products/cart/product-1.jpg"
//                           alt="product"
//                         />
//                       </a>
//                     </figure>
//                     <a href="#" className="btn-remove" title="Remove Product">
//                       <i className="icon-close" />
//                     </a>
//                   </div>
//                   {/* End .product */}
//                   <div className="product">
//                     <div className="product-cart-details">
//                       <h4 className="product-title">
//                         <a href="product.html">
//                           Blue utility pinafore denim dress
//                         </a>
//                       </h4>
//                       <span className="cart-product-info">
//                         <span className="cart-product-qty">1</span>x $76.00
//                       </span>
//                     </div>
//                     {/* End .product-cart-details */}
//                     <figure className="product-image-container">
//                       <a href="product.html" className="product-image">
//                         <img
//                           src="assets/images/products/cart/product-2.jpg"
//                           alt="product"
//                         />
//                       </a>
//                     </figure>
//                     <a href="#" className="btn-remove" title="Remove Product">
//                       <i className="icon-close" />
//                     </a>
//                   </div>
//                   {/* End .product */}
//                 </div>
//                 {/* End .cart-product */}
//                 <div className="dropdown-cart-total">
//                   <span>Total</span>
//                   <span className="cart-total-price">$160.00</span>
//                 </div>
//                 {/* End .dropdown-cart-total */}
//                 <div className="dropdown-cart-action">
//                   <a href="cart.html" className="btn btn-primary">
//                     View Cart
//                   </a>
//                   <a href="checkout.html" className="btn btn-outline-primary-2">
//                     <span>Checkout</span>
//                     <i className="icon-long-arrow-right" />
//                   </a>
//                 </div>
//                 {/* End .dropdown-cart-total */}
//               </div>
//               {/* End .dropdown-menu */}
//             </div>
//             {/* End .cart-dropdown */}
//           </div>
//           {/* End .header-right */}
//         </div>
//         {/* End .container */}
//       </div>
//       {/* End .header-middle */}
//     </header>
//   );
// };

// export { SecondNav };
