import React from "react";

const About = () => {
  return (
    <>
      <div className="page-wrapper">
        <main className="main">
          <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">Pages</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About us
                </li>
              </ol>
            </div>
            {/* End .container */}
          </nav>
          {/* End .breadcrumb-nav */}
          <div className="container">
            <div
              className="page-header page-header-big text-center"
              style={{
                backgroundImage: 'url("assets/images/about-header-bg.jpg")',
              }}
            >
              <h1 className="page-title text-white">
                About us<span className="text-white">Who we are</span>
              </h1>
            </div>
            {/* End .page-header */}
          </div>
          {/* End .container */}
          <div className="page-content pb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <h2 className="title">Our Vision</h2>
                  {/* End .title */}
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In
                    nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed
                    pretium, ligula sollicitudin laoreet viverra, tortor libero
                    sodales leo, eget blandit nunc tortor eu nibh.{" "}
                  </p>
                </div>
                {/* End .col-lg-6 */}
                <div className="col-lg-6">
                  <h2 className="title">Our Mission</h2>
                  {/* End .title */}
                  <p>
                    Sed egestas, ante et vulputate volutpat, eros pede semper
                    est, vitae luctus metus libero eu augue. Morbi purus libero,
                    faucibus adipiscing, commodo quis, gravida id, est. Sed
                    lectus. <br />
                    Praesent elementum hendrerit tortor. Sed semper lorem at
                    felis.{" "}
                  </p>
                </div>
                {/* End .col-lg-6 */}
              </div>
              {/* End .row */}
              <div className="mb-5" />
              {/* End .mb-4 */}
            </div>
            {/* End .container */}
            <div className="bg-light-2 pt-6 pb-5 mb-6 mb-lg-8">
              <div className="container">
                <div className="row">
                  <div className="col-lg-5 mb-3 mb-lg-0">
                    <h2 className="title">Who We Are</h2>
                    {/* End .title */}
                    <p className="lead text-primary mb-3">
                      Pellentesque odio nisi, euismod pharetra a ultricies{" "}
                      <br />
                      in diam. Sed arcu. Cras consequat
                    </p>
                    {/* End .lead text-primary */}
                    <p className="mb-2">
                      Sed pretium, ligula sollicitudin laoreet viverra, tortor
                      libero sodales leo, eget blandit nunc tortor eu nibh.
                      Suspendisse potenti. Sed egestas, ante et vulputate
                      volutpat, uctus metus libero eu augue.{" "}
                    </p>
                    <a
                      href="blog.html"
                      className="btn btn-sm btn-minwidth btn-outline-primary-2"
                    >
                      <span>VIEW OUR NEWS</span>
                      <i className="icon-long-arrow-right" />
                    </a>
                  </div>
                  {/* End .col-lg-5 */}
                  <div className="col-lg-6 offset-lg-1">
                    <div className="about-images">
                      <img
                        src="assets/images/about/img-1.jpg"
                        alt
                        className="about-img-front"
                      />
                      <img
                        src="assets/images/about/img-2.jpg"
                        alt
                        className="about-img-back"
                      />
                    </div>
                    {/* End .about-images */}
                  </div>
                  {/* End .col-lg-6 */}
                </div>
                {/* End .row */}
              </div>
              {/* End .container */}
            </div>
            {/* End .bg-light-2 pt-6 pb-6 */}
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="brands-text">
                    <h2 className="title">
                      The world&apos;s premium design brands in one destination.
                    </h2>
                    {/* End .title */}
                    <p>
                      Phasellus hendrerit. Pellentesque aliquet nibh nec urna.
                      In nisi neque, aliquet vel, dapibus id, mattis vel, nis
                    </p>
                  </div>
                  {/* End .brands-text */}
                </div>
                {/* End .col-lg-5 */}
                <div className="col-lg-7">
                  <div className="brands-display">
                    <div className="row justify-content-center">
                      <div className="col-6 col-sm-4">
                        <a href="#" className="brand">
                          <img
                            src="assets/images/brands/1.png"
                            alt="Brand Name"
                          />
                        </a>
                      </div>
                      {/* End .col-sm-4 */}
                      <div className="col-6 col-sm-4">
                        <a href="#" className="brand">
                          <img
                            src="assets/images/brands/2.png"
                            alt="Brand Name"
                          />
                        </a>
                      </div>
                      {/* End .col-sm-4 */}
                      <div className="col-6 col-sm-4">
                        <a href="#" className="brand">
                          <img
                            src="assets/images/brands/3.png"
                            alt="Brand Name"
                          />
                        </a>
                      </div>
                      {/* End .col-sm-4 */}
                      <div className="col-6 col-sm-4">
                        <a href="#" className="brand">
                          <img
                            src="assets/images/brands/4.png"
                            alt="Brand Name"
                          />
                        </a>
                      </div>
                      {/* End .col-sm-4 */}
                      <div className="col-6 col-sm-4">
                        <a href="#" className="brand">
                          <img
                            src="assets/images/brands/5.png"
                            alt="Brand Name"
                          />
                        </a>
                      </div>
                      {/* End .col-sm-4 */}
                      <div className="col-6 col-sm-4">
                        <a href="#" className="brand">
                          <img
                            src="assets/images/brands/6.png"
                            alt="Brand Name"
                          />
                        </a>
                      </div>
                      {/* End .col-sm-4 */}
                      <div className="col-6 col-sm-4">
                        <a href="#" className="brand">
                          <img
                            src="assets/images/brands/7.png"
                            alt="Brand Name"
                          />
                        </a>
                      </div>
                      {/* End .col-sm-4 */}
                      <div className="col-6 col-sm-4">
                        <a href="#" className="brand">
                          <img
                            src="assets/images/brands/8.png"
                            alt="Brand Name"
                          />
                        </a>
                      </div>
                      {/* End .col-sm-4 */}
                      <div className="col-6 col-sm-4">
                        <a href="#" className="brand">
                          <img
                            src="assets/images/brands/9.png"
                            alt="Brand Name"
                          />
                        </a>
                      </div>
                      {/* End .col-sm-4 */}
                    </div>
                    {/* End .row */}
                  </div>
                  {/* End .brands-display */}
                </div>
                {/* End .col-lg-7 */}
              </div>
              {/* End .row */}
              <hr className="mt-4 mb-6" />
              <h2 className="title text-center mb-4">Meet Our Team</h2>
              {/* End .title text-center mb-2 */}
              <div className="row">
                <div className="col-md-4">
                  <div className="member member-anim text-center">
                    <figure className="member-media">
                      <img
                        src="assets/images/team/member-1.jpg"
                        alt="member photo"
                      />
                      <figcaption className="member-overlay">
                        <div className="member-overlay-content">
                          <h3 className="member-title">
                            Samanta Grey<span>Founder &amp; CEO</span>
                          </h3>
                          {/* End .member-title */}
                          <p>
                            Sed pretium, ligula sollicitudin viverra, tortor
                            libero sodales leo, eget blandit nunc.
                          </p>
                          <div className="social-icons social-icons-simple">
                            <a
                              href="#"
                              className="social-icon"
                              title="Facebook"
                              target="_blank"
                            >
                              <i className="icon-facebook-f" />
                            </a>
                            <a
                              href="#"
                              className="social-icon"
                              title="Twitter"
                              target="_blank"
                            >
                              <i className="icon-twitter" />
                            </a>
                            <a
                              href="#"
                              className="social-icon"
                              title="Instagram"
                              target="_blank"
                            >
                              <i className="icon-instagram" />
                            </a>
                          </div>
                          {/* End .soial-icons */}
                        </div>
                        {/* End .member-overlay-content */}
                      </figcaption>
                      {/* End .member-overlay */}
                    </figure>
                    {/* End .member-media */}
                    <div className="member-content">
                      <h3 className="member-title">
                        Samanta Grey<span>Founder &amp; CEO</span>
                      </h3>
                      {/* End .member-title */}
                    </div>
                    {/* End .member-content */}
                  </div>
                  {/* End .member */}
                </div>
                {/* End .col-md-4 */}
                <div className="col-md-4">
                  <div className="member member-anim text-center">
                    <figure className="member-media">
                      <img
                        src="assets/images/team/member-2.jpg"
                        alt="member photo"
                      />
                      <figcaption className="member-overlay">
                        <div className="member-overlay-content">
                          <h3 className="member-title">
                            Bruce Sutton
                            <span>Sales &amp; Marketing Manager</span>
                          </h3>
                          {/* End .member-title */}
                          <p>
                            Sed pretium, ligula sollicitudin viverra, tortor
                            libero sodales leo, eget blandit nunc.
                          </p>
                          <div className="social-icons social-icons-simple">
                            <a
                              href="#"
                              className="social-icon"
                              title="Facebook"
                              target="_blank"
                            >
                              <i className="icon-facebook-f" />
                            </a>
                            <a
                              href="#"
                              className="social-icon"
                              title="Twitter"
                              target="_blank"
                            >
                              <i className="icon-twitter" />
                            </a>
                            <a
                              href="#"
                              className="social-icon"
                              title="Instagram"
                              target="_blank"
                            >
                              <i className="icon-instagram" />
                            </a>
                          </div>
                          {/* End .soial-icons */}
                        </div>
                        {/* End .member-overlay-content */}
                      </figcaption>
                      {/* End .member-overlay */}
                    </figure>
                    {/* End .member-media */}
                    <div className="member-content">
                      <h3 className="member-title">
                        Bruce Sutton<span>Sales &amp; Marketing Manager</span>
                      </h3>
                      {/* End .member-title */}
                    </div>
                    {/* End .member-content */}
                  </div>
                  {/* End .member */}
                </div>
                {/* End .col-md-4 */}
                <div className="col-md-4">
                  <div className="member member-anim text-center">
                    <figure className="member-media">
                      <img
                        src="assets/images/team/member-3.jpg"
                        alt="member photo"
                      />
                      <figcaption className="member-overlay">
                        <div className="member-overlay-content">
                          <h3 className="member-title">
                            Janet Joy<span>Product Manager</span>
                          </h3>
                          {/* End .member-title */}
                          <p>
                            Sed pretium, ligula sollicitudin viverra, tortor
                            libero sodales leo, eget blandit nunc.
                          </p>
                          <div className="social-icons social-icons-simple">
                            <a
                              href="#"
                              className="social-icon"
                              title="Facebook"
                              target="_blank"
                            >
                              <i className="icon-facebook-f" />
                            </a>
                            <a
                              href="#"
                              className="social-icon"
                              title="Twitter"
                              target="_blank"
                            >
                              <i className="icon-twitter" />
                            </a>
                            <a
                              href="#"
                              className="social-icon"
                              title="Instagram"
                              target="_blank"
                            >
                              <i className="icon-instagram" />
                            </a>
                          </div>
                          {/* End .soial-icons */}
                        </div>
                        {/* End .member-overlay-content */}
                      </figcaption>
                      {/* End .member-overlay */}
                    </figure>
                    {/* End .member-media */}
                    <div className="member-content">
                      <h3 className="member-title">
                        Janet Joy<span>Product Manager</span>
                      </h3>
                      {/* End .member-title */}
                    </div>
                    {/* End .member-content */}
                  </div>
                  {/* End .member */}
                </div>
                {/* End .col-md-4 */}
              </div>
              {/* End .row */}
            </div>
            {/* End .container */}
            <div className="mb-2" />
            {/* End .mb-2 */}
            <div className="about-testimonials bg-light-2 pt-6 pb-6">
              <div className="container">
                <h2 className="title text-center mb-3">
                  What Customer Say About Us
                </h2>
                {/* End .title text-center */}
                <div
                  className="owl-carousel owl-simple owl-testimonials-photo"
                  data-toggle="owl"
                  data-owl-options='{
                            "nav": false, 
                            "dots": true,
                            "margin": 20,
                            "loop": false,
                            "responsive": {
                                "1200": {
                                    "nav": true
                                }
                            }
                        }'
                >
                  <blockquote className="testimonial text-center">
                    <img
                      src="assets/images/testimonials/user-1.jpg"
                      alt="user"
                    />
                    <p>
                      “ Lorem ipsum dolor sit amet, consectetuer adipiscing
                      elit. Pellentesque aliquet nibh nec urna. <br />
                      In nisi neque, aliquet vel, dapibus id, mattis vel, nisi.
                      Sed pretium, ligula sollicitudin laoreet viverra, tortor
                      libero sodales leo, eget blandit nunc tortor eu nibh.
                      Nullam mollis. Ut justo. Suspendisse potenti. ”
                    </p>
                    <cite>
                      Jenson Gregory
                      <span>Customer</span>
                    </cite>
                  </blockquote>
                  {/* End .testimonial */}
                  <blockquote className="testimonial text-center">
                    <img
                      src="assets/images/testimonials/user-2.jpg"
                      alt="user"
                    />
                    <p>
                      “ Impedit, ratione sequi, sunt incidunt magnam et.
                      Delectus obcaecati optio eius error libero perferendis
                      nesciunt atque dolores magni recusandae! Doloremque quidem
                      error eum quis similique doloribus natus qui ut
                      ipsum.Velit quos ipsa exercitationem, vel unde obcaecati
                      impedit eveniet non. ”
                    </p>
                    <cite>
                      Victoria Ventura
                      <span>Customer</span>
                    </cite>
                  </blockquote>
                  {/* End .testimonial */}
                </div>
                {/* End .testimonials-slider owl-carousel */}
              </div>
              {/* End .container */}
            </div>
            {/* End .bg-light-2 pt-5 pb-6 */}
          </div>
          {/* End .page-content */}
        </main>
        {/* End .main */}
      </div>
      {/* End .page-wrapper */}

      {/* Mobile Menu */}
      <div className="mobile-menu-overlay" />
      {/* End .mobil-menu-overlay */}

      <div className="mobile-menu-container">
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close">
            <i className="icon-close" />
          </span>
          <form action="#" method="get" className="mobile-search">
            <label htmlFor="mobile-search" className="sr-only">
              Search
            </label>
            <input
              type="search"
              className="form-control"
              name="mobile-search"
              id="mobile-search"
              placeholder="Search in..."
              required
            />
            <button className="btn btn-primary" type="submit">
              <i className="icon-search" />
            </button>
          </form>
          <nav className="mobile-nav">
            <ul className="mobile-menu">
              <li className="active">
                <a href="index.html">Home</a>
                <ul>
                  <li>
                    <a href="index-1.html">01 - furniture store</a>
                  </li>
                  <li>
                    <a href="index-2.html">02 - furniture store</a>
                  </li>
                  <li>
                    <a href="index-3.html">03 - electronic store</a>
                  </li>
                  <li>
                    <a href="index-4.html">04 - electronic store</a>
                  </li>
                  <li>
                    <a href="index-5.html">05 - fashion store</a>
                  </li>
                  <li>
                    <a href="index-6.html">06 - fashion store</a>
                  </li>
                  <li>
                    <a href="index-7.html">07 - fashion store</a>
                  </li>
                  <li>
                    <a href="index-8.html">08 - fashion store</a>
                  </li>
                  <li>
                    <a href="index-9.html">09 - fashion store</a>
                  </li>
                  <li>
                    <a href="index-10.html">10 - shoes store</a>
                  </li>
                  <li>
                    <a href="index-11.html">11 - furniture simple store</a>
                  </li>
                  <li>
                    <a href="index-12.html">12 - fashion simple store</a>
                  </li>
                  <li>
                    <a href="index-13.html">13 - market</a>
                  </li>
                  <li>
                    <a href="index-14.html">14 - market fullwidth</a>
                  </li>
                  <li>
                    <a href="index-15.html">15 - lookbook 1</a>
                  </li>
                  <li>
                    <a href="index-16.html">16 - lookbook 2</a>
                  </li>
                  <li>
                    <a href="index-17.html">17 - fashion store</a>
                  </li>
                  <li>
                    <a href="index-18.html">
                      18 - fashion store (with sidebar)
                    </a>
                  </li>
                  <li>
                    <a href="index-19.html">19 - games store</a>
                  </li>
                  <li>
                    <a href="index-20.html">20 - book store</a>
                  </li>
                  <li>
                    <a href="index-21.html">21 - sport store</a>
                  </li>
                  <li>
                    <a href="index-22.html">22 - tools store</a>
                  </li>
                  <li>
                    <a href="index-23.html">
                      23 - fashion left navigation store
                    </a>
                  </li>
                  <li>
                    <a href="index-24.html">24 - extreme sport store</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="category.html">Shop</a>
                <ul>
                  <li>
                    <a href="category-list.html">Shop List</a>
                  </li>
                  <li>
                    <a href="category-2cols.html">Shop Grid 2 Columns</a>
                  </li>
                  <li>
                    <a href="category.html">Shop Grid 3 Columns</a>
                  </li>
                  <li>
                    <a href="category-4cols.html">Shop Grid 4 Columns</a>
                  </li>
                  <li>
                    <a href="category-boxed.html">
                      <span>
                        Shop Boxed No Sidebar
                        <span className="tip tip-hot">Hot</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="category-fullwidth.html">
                      Shop Fullwidth No Sidebar
                    </a>
                  </li>
                  <li>
                    <a href="product-category-boxed.html">
                      Product Category Boxed
                    </a>
                  </li>
                  <li>
                    <a href="product-category-fullwidth.html">
                      <span>
                        Product Category Fullwidth
                        <span className="tip tip-new">New</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="cart.html">Cart</a>
                  </li>
                  <li>
                    <a href="checkout.html">Checkout</a>
                  </li>
                  <li>
                    <a href="wishlist.html">Wishlist</a>
                  </li>
                  <li>
                    <a href="#">Lookbook</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="product.html" className="sf-with-ul">
                  Product
                </a>
                <ul>
                  <li>
                    <a href="product.html">Default</a>
                  </li>
                  <li>
                    <a href="product-centered.html">Centered</a>
                  </li>
                  <li>
                    <a href="product-extended.html">
                      <span>
                        Extended Info<span className="tip tip-new">New</span>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="product-gallery.html">Gallery</a>
                  </li>
                  <li>
                    <a href="product-sticky.html">Sticky Info</a>
                  </li>
                  <li>
                    <a href="product-sidebar.html">Boxed With Sidebar</a>
                  </li>
                  <li>
                    <a href="product-fullwidth.html">Full Width</a>
                  </li>
                  <li>
                    <a href="product-masonry.html">Masonry Sticky Info</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">Pages</a>
                <ul>
                  <li>
                    <a href="about.html">About</a>
                    <ul>
                      <li>
                        <a href="about.html">About 01</a>
                      </li>
                      <li>
                        <a href="about-2.html">About 02</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                    <ul>
                      <li>
                        <a href="contact.html">Contact 01</a>
                      </li>
                      <li>
                        <a href="contact-2.html">Contact 02</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="login.html">Login</a>
                  </li>
                  <li>
                    <a href="faq.html">FAQs</a>
                  </li>
                  <li>
                    <a href="404.html">Error 404</a>
                  </li>
                  <li>
                    <a href="coming-soon.html">Coming Soon</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="blog.html">Blog</a>
                <ul>
                  <li>
                    <a href="blog.html">Classic</a>
                  </li>
                  <li>
                    <a href="blog-listing.html">Listing</a>
                  </li>
                  <li>
                    <a href="#">Grid</a>
                    <ul>
                      <li>
                        <a href="blog-grid-2cols.html">Grid 2 columns</a>
                      </li>
                      <li>
                        <a href="blog-grid-3cols.html">Grid 3 columns</a>
                      </li>
                      <li>
                        <a href="blog-grid-4cols.html">Grid 4 columns</a>
                      </li>
                      <li>
                        <a href="blog-grid-sidebar.html">Grid sidebar</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Masonry</a>
                    <ul>
                      <li>
                        <a href="blog-masonry-2cols.html">Masonry 2 columns</a>
                      </li>
                      <li>
                        <a href="blog-masonry-3cols.html">Masonry 3 columns</a>
                      </li>
                      <li>
                        <a href="blog-masonry-4cols.html">Masonry 4 columns</a>
                      </li>
                      <li>
                        <a href="blog-masonry-sidebar.html">Masonry sidebar</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Mask</a>
                    <ul>
                      <li>
                        <a href="blog-mask-grid.html">Blog mask grid</a>
                      </li>
                      <li>
                        <a href="blog-mask-masonry.html">Blog mask masonry</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Single Post</a>
                    <ul>
                      <li>
                        <a href="single.html">Default with sidebar</a>
                      </li>
                      <li>
                        <a href="single-fullwidth.html">Fullwidth no sidebar</a>
                      </li>
                      <li>
                        <a href="single-fullwidth-sidebar.html">
                          Fullwidth with sidebar
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <a href="elements-list.html">Elements</a>
                <ul>
                  <li>
                    <a href="elements-products.html">Products</a>
                  </li>
                  <li>
                    <a href="elements-typography.html">Typography</a>
                  </li>
                  <li>
                    <a href="elements-titles.html">Titles</a>
                  </li>
                  <li>
                    <a href="elements-banners.html">Banners</a>
                  </li>
                  <li>
                    <a href="elements-product-category.html">
                      Product Category
                    </a>
                  </li>
                  <li>
                    <a href="elements-video-banners.html">Video Banners</a>
                  </li>
                  <li>
                    <a href="elements-buttons.html">Buttons</a>
                  </li>
                  <li>
                    <a href="elements-accordions.html">Accordions</a>
                  </li>
                  <li>
                    <a href="elements-tabs.html">Tabs</a>
                  </li>
                  <li>
                    <a href="elements-testimonials.html">Testimonials</a>
                  </li>
                  <li>
                    <a href="elements-blog-posts.html">Blog Posts</a>
                  </li>
                  <li>
                    <a href="elements-portfolio.html">Portfolio</a>
                  </li>
                  <li>
                    <a href="elements-cta.html">Call to Action</a>
                  </li>
                  <li>
                    <a href="elements-icon-boxes.html">Icon Boxes</a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          {/* End .mobile-nav */}
          <div className="social-icons">
            <a
              href="#"
              className="social-icon"
              target="_blank"
              title="Facebook"
            >
              <i className="icon-facebook-f" />
            </a>
            <a href="#" className="social-icon" target="_blank" title="Twitter">
              <i className="icon-twitter" />
            </a>
            <a
              href="#"
              className="social-icon"
              target="_blank"
              title="Instagram"
            >
              <i className="icon-instagram" />
            </a>
            <a href="#" className="social-icon" target="_blank" title="Youtube">
              <i className="icon-youtube" />
            </a>
          </div>
          {/* End .social-icons */}
        </div>
        {/* End .mobile-menu-wrapper */}
      </div>
      {/* End .mobile-menu-container */}
    </>
  );
};

export default About;
